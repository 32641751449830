import React,  { useState } from "react";
import Header from "../components/header/header"
import Helmet from "react-helmet";

export default function Poetry(props) {
  const [open, setOpen] = useState(false);
  return (
    <div className="index">
      <Helmet>
          <meta charSet="utf-8" />
          <title>Poetry</title>
      </Helmet>
      <Header page='poetry' open={open} setOpen={setOpen}/>
      <div className='content'>
      <div className='textBox'>
      <img src={'/reading.jpg'} alt='reading' className="reading"></img>
     <h3>Poems:</h3>
<p>"Another houseplant dies while I consider the transcendental" in Poetry Northwest (forthcoming)</p>
<p>"Flying home for the third funeral this year" in Cimarron Review (forthcoming)</p>
<p>"Cathexis Blues" in Salamander (forthcoming summer 2022)</p>
<p> "State of Sorry" in <a href='http://greensbororeview.org/stories/state-of-sorry/'> The Greensboro Review</a>  </p>
<p>"Resurrection purgatory in the laundromat" in <a href='https://www.poetryfoundation.org/poetrymagazine/poems/155913/resurrection-purgatory-in-the-laundromat'>Poetry Magazine</a></p>
<p> <a href='https://theindianapolisreview.com/vital/'>"Vital"</a> & <a href='https://theindianapolisreview.com/luck/'>"Luck"</a> in The Indianapolis Review</p>
<p>“You don’t know if you should laugh…” & “Peak Inertia” in <a href='https://www.hobartpulp.com/web_features/peak-inertia'>Hobart</a></p>

<p>3 poems in <a href='http://sporkpress.com/sporklet/10_20/edwards.html'> Sporklet</a> </p>

<p>“Alpine Dam” & “A god of smaller questions” in <a href='https://www.stirringlit.com/vol-22-ed-3-p-two-poems-by-julia-edwards'>Stirring Lit</a></p> 

<p>“Medicinal” in <a href='http://www.batcityreview.org/issue-15'>Bat City Review</a></p>

<p>“Breadcrumb #311 [My body is an inlet]” in <a href='http://breadcrumbsmag.com/blog/311'>Breadcrumbs Magazine</a></p>

<p>“27,” “Exit Row,” & “Midwestern Halloween” in <a href='https://acrossthemargin.com/juliaedwardspoetry/'>Across the Margin</a></p> 

<p>Select poems in <a href='https://girlbloodinfo.wordpress.com/'>Girl Blood Info zine series</a></p>

<h3>Select interviews:</h3>

<p>Poetry Interview with Diane Seuss in <a href='http://diodepoetry.com/seuss_edwards/'>Diode</a></p>

<p>Testaments to Still Being Alive: Interview with Emily Nason in <a href='http://greensbororeview.org/an-interview-with-emily-nason/'>The Greensboro Review blog</a></p>

<p>Video Interview with & performance by Mitski in <a href='https://www.bkmag.com/2014/12/22/live-at-brooklyn-magazine-mitski-performs-townie-and-others/'>Brooklyn Magazine</a></p>

<p>Video Interview with & performance by Greta Kline of Frankie Cosmos in <a href='https://www.bkmag.com/2014/12/15/live-at-brooklyn-magazine-frankie-cosmos-performs-under-a-handmade-cosmos/'>Brooklyn Magazine</a></p>

<p>Interview with Henry Crawford of Small Wonder in <a href='https://www.bkmag.com/2015/01/27/small-wonder-brooklyn-indie-pop-goes-native/'> Brooklyn Magazine</a></p>



<h3>Select articles:</h3>

<p>Book Review: Rue by Kathryn Nuernberger in <a href='https://thecarolinaquarterly.com/2021/06/rue-a-review/'>The Carolina Quarterly</a></p>

<p>“Wizardchan: The online home for ‘magical’ 30-something male virgins” in <a href='https://splinternews.com/wizardchan-the-online-home-for-magical-30-something-ma-1793860899'>Fusion</a></p>
 

</div>
      </div>
    </div>
  )
}
